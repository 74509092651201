import React from 'react'

import MenuTitle from '../MenuTitle'
import Socmed from './Socmed'

const FooterAside = ({content}) => {
    return (
        <div className="mt-16 flex flex-col text-center w-full md:flex-row md:text-left md:justify-between">
            <div className="mb-8md:mb-0md:mr-12">
                <p className="mb-0">
                    {content?.labelKementrianInvestasi}<br />
                    {content?.labelKantorPusat}:<br />
                    Jl. Jend. Gatot Subroto No. 44, Jakarta 12190 P.O. Box 3186, Indonesia
                </p>
            </div>
            <div>
                <MenuTitle>{content?.labelIkutiKami}</MenuTitle>
                <Socmed />
            </div>
        </div>
    )
}
 
export default FooterAside