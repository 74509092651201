import React, { useState, useContext } from "react"
import Modal from "react-modal"
import ArrowButton from "components/UI/ArrowButton"
import LanguageContext from "components/ctx/language-context"
import { withPrefix } from "gatsby"
import axios from "axios"

function SopButton() {
  const [surveyModalIsOpen, setSurveyModalIsOpen] = useState(false)
  const [surveyLoginIsOpen, setSurveyLoginIsOpen] = useState(false)
  const [txtEmail, setTxtEmail] = useState(null)
  const openModal = () => setSurveyModalIsOpen(true)
  const closedModal = () => setSurveyModalIsOpen(false)

  const openModalLogin = () => setSurveyLoginIsOpen(true)
  const closedModalLogin = () => setSurveyLoginIsOpen(false)

  const langCtx = useContext(LanguageContext)

  const btnValidate = async () => {
    if (!txtEmail) {
      alert("email harus diisi")
      return null
    }
    const result = await axios.get(
      `${process.env.APIURLV2}api/Webs/GetUserByEmail?email=${txtEmail}`
    )
    if (result.data.isSuccess) {
      closedModalLogin()
      openModal()
    } else {
      alert("email tidak terdaftar")
    }
  }

  return (
    <>
      <Modal
        isOpen={surveyModalIsOpen}
        onRequestClose={closedModal}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="p-0 w-10/12"
        style={{ content: { maxWidth: "650px" } }}
      >
        <div className="bg-white rounded-lg p-8">
          <div className="mb-5">
            <h5 className="text-bkpm-dark-blue-01">
              {langCtx.language === "id"
                ? "Keputusan Menteri/Kepala Badan Koordinasi Penanaman Modal No. 50 Tahun 2023 Tentang Panduan Penyusunan Potensi dan Peluang Investasi Daerah"
                : "Keputusan Menteri/Kepala Badan Koordinasi Penanaman Modal No. 50 Tahun 2023 Tentang Panduan Penyusunan Potensi dan Peluang Investasi Daerah"}
            </h5>
            <div className="mt-2">
              <ArrowButton
                buttonClick={() =>
                  window.open(withPrefix("/pdf/sk_mentri_investasi.pdf"))
                }
                buttonStyle="expanded"
              >
                {langCtx.language === "id" ? "Unduh " : "Download"} PDF
              </ArrowButton>
            </div>
          </div>
          <div className="mb-5">
            <h5 className="text-bkpm-dark-blue-01">
              {langCtx.language === "id"
                ? "Panduan Penyusunan Potensi Sektor dan Peluang Investasi Daerah"
                : "Guidelines for Developing Sector Potential and Regional Investment Opportunities"}
            </h5>
            <div className="mt-2">
              <ArrowButton
                buttonClick={() =>
                  window.open(withPrefix("/pdf/sop_teori.pdf"))
                }
                buttonStyle="expanded"
              >
                {langCtx.language === "id" ? "Unduh " : "Download"} PDF
              </ArrowButton>
            </div>
          </div>
          <div>
            <h5 className="text-bkpm-dark-blue-01">
              {langCtx.language === "id"
                ? "Panduan Teknis Pengisian Profil Sektor Unggulan dan Peluang Investasi Daerah"
                : "Technical guide for filling out the leading sector profile and regional investment opportunities"}
            </h5>
            <div className="mt-2">
              <ArrowButton
                buttonClick={() =>
                  window.open(withPrefix("/pdf/sop_teknis.pdf"))
                }
                buttonStyle="expanded"
              >
                {langCtx.language === "id" ? "Unduh " : "Download"} PDF
              </ArrowButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={surveyLoginIsOpen}
        onRequestClose={closedModalLogin}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="p-0 w-10/12"
        style={{ content: { maxWidth: "650px" } }}
      >
        <div className="bg-white rounded-lg p-8">
          <div className="mb-5">
            <h5 className="text-bkpm-dark-blue-01">
              {langCtx.language === "id"
                ? "Masukkan username untuk mengunduh dokumen"
                : "Enter your username to download the document"}
            </h5>
            <div className="mt-2">
              <input
                type="text"
                className="rounded-md border-gray-300 w-[100%]"
                placeholder={
                  langCtx.language === "id"
                    ? `Ketikkan Email anda...`
                    : `Enter your email here...`
                }
                onChange={e => setTxtEmail(e.target.value)}
                classNamePrefix="addl-class"
              />
            </div>

            <div className="mt-6">
              <ArrowButton
                buttonClick={() => btnValidate()}
                buttonStyle="expanded"
              >
                {langCtx.language === "id" ? "Unduh " : "Download"} PDF
              </ArrowButton>
            </div>
          </div>
        </div>
      </Modal>
      <div className="mt-8">
        <ArrowButton
          buttonStyle="expanded"
          colorVar="orange"
          buttonClick={openModalLogin}
        >
          {langCtx.language === "id"
            ? "Panduan Peluang Investasi"
            : "Investment Opportunity Guide"}
        </ArrowButton>
      </div>
    </>
  )
}

export default SopButton
