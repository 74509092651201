import React from "react"

import ArrowButton from "components/UI/ArrowButton"

const LoginButton = () => (
  <div className="mt-8">
    <ArrowButton
      buttonClick={() =>
        window.open("https://regionalinvestment.bkpm.go.id/admin/")
      }
      buttonStyle="expanded"
    >
      Login Admin
    </ArrowButton>
  </div>
)

export default LoginButton
