import React from 'react'
import tw from 'tailwind-styled-components'

const FooterContactDetails = (props) => {
    return (
        <Container>
            <ContactContent>
                <ContactTitle>{ props.label }</ContactTitle>
                { props.children }
            </ContactContent>
        </Container>
    );
}

const Container = tw.div`
    mb-4
`
const ContactTitle = tw.span`
    font-normal
    block
`
const ContactContent = tw.p`
    mb-0
    text-sm
    font-bold
    leading-tight
`

export default FooterContactDetails;