import React, { memo, useContext, useEffect, useState } from "react"

import ResponsiveContainer from "components/UI/ResponsiveContainer"
import Main from "./Main"
import Aside from "./Aside"
import axios from "axios"
import LanguageContext from "components/ctx/language-context"
import { graphql, link, useStaticQuery } from "gatsby"

const Footer = () => {
  const langCtx = useContext(LanguageContext)
  const [content, setContent] = useState(null)
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiUrl
          }
        }
      }
    `
  )
  useEffect(async () => {
    // console.log("footer req API")
    const url = process.env.APIURL + "api/FrontEnd/GetMenuFooter"
    const result = await axios.get(url, {
      headers: {
        "X-localization": langCtx.language,
      },
    })

    setContent({ ...result.data.Datas })
  }, [langCtx])
  return (
    <footer className="bg-gradient-to-b from-bkpm-dark-blue-01 to-bkpm-dark-blue-02 py-12 text-gray-50">
      <ResponsiveContainer>
        {content !== null && <Main content={content} />}
        <Aside content={content?.footer} />
      </ResponsiveContainer>
    </footer>
  )
}

export default memo(Footer)
