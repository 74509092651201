import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const MenuLink = ({ children, menuLink, state }) => {
  return (
    <Link
      to={menuLink}
      className="text-white block leading-relaxed py-1 hover:text-bkpm-light-blue-01"
    >
      {children}
    </Link>
  )
}

MenuLink.propTypes = {
  menuLink: PropTypes.string.isRequired,
}

export default MenuLink
