import React from 'react'
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube } from 'react-icons/fa'

import SocmedLink from './SocmedLink'

const Socmed = () => {
    return (
        <div className="inline-grid gap-4 grid-flow-col">
            <SocmedLink link="https://www.instagram.com/bkpm_id" icon={ <FaInstagram  /> } />
            <SocmedLink link="https://www.facebook.com/BKPMINDONESIA/" icon={ <FaFacebook  /> } />
            <SocmedLink link="https://www.linkedin.com/company/indonesia-investment-coordinating-board/" icon={ <FaLinkedin  /> } />
            <SocmedLink link="https://www.youtube.com/c/BKPMInvestIndonesia" icon={ <FaYoutube /> } />
        </div>
    )
}

export default Socmed
