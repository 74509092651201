import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import Modal from "react-modal"
import ArrowButton from "components/UI/ArrowButton"
import LanguageContext from "components/ctx/language-context"

function SurveyButton() {
  const [surveyModalIsOpen, setSurveyModalIsOpen] = useState(false)
  const openModal = () => setSurveyModalIsOpen(true)
  const closedModal = () => setSurveyModalIsOpen(false)

  const [content, setContent] = useState([])
  const langCtx = useContext(LanguageContext)

  useEffect(async () => {
    const url = process.env.APIURL + "api/FrontEnd/GetInfoSurvey"
    const result = await axios.get(url, {
      headers: {
        "X-localization": langCtx.language,
      },
    })

    setContent({ ...result.data.Data })
  }, [])
  return (
    <>
      <Modal
        isOpen={surveyModalIsOpen}
        onRequestClose={closedModal}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="p-0 w-10/12"
        style={{ content: { maxWidth: "650px" } }}
      >
        <div className="bg-white rounded-lg p-8">
          {/* <h5 className="text-bkpm-dark-blue-01">
            Bantu Kami Untuk meningkatkan kualitas website ini dengan
          </h5> */}
          {content ? (
            <h5 className="text-bkpm-dark-blue-01">{content.deskripsi}</h5>
          ) : (
            ""
          )}
          <div className="mt-8">
            {content ? (
              <ArrowButton
                buttonClick={() => window.open(`${content.url}`)}
                buttonStyle="expanded"
              >
                {content.labelLink}
              </ArrowButton>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
      <div className="mt-8">
        <ArrowButton
          buttonStyle="expanded"
          colorVar="green"
          buttonClick={openModal}
        >
          {content ? "Survey " : ""}
        </ArrowButton>
      </div>
    </>
  )
}

export default SurveyButton
