import React from 'react'
import PropTypes from 'prop-types'

const SocmedLink = (props) =>  <a href={ props.link } className="bg-white flex justify-center items-center text-bkpm-light-blue-01 w-6 h-6 rounded-full">{ props.icon }</a>

SocmedLink.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired
}
 
export default SocmedLink;