import React from "react"

import ContactDetails from "./ContactDetails"

const Contact = ({ content }) => {
  return (
    <>
      <ContactDetails label={content.labelPhone}>
        <a href="tel:+62215252008" className="hover:text-bkpm-light-blue-01">
          +6221 525 2008
        </a>
      </ContactDetails>
      {/* <ContactDetails label={content.labelEmail}><a href="mailto:sipid@bkpm.go.id" className="hover:text-bkpm-light-blue-01">sipid@bkpm.go.id</a></ContactDetails> */}
    </>
  )
}

export default Contact
