import React from "react"
import { Link } from "gatsby"
import MenuTitle from "../MenuTitle"
import Logo from "./Logo"
import Contact from "./Contact"
import MenuCol from "./MenuCol"
import MenuLink from "./MenuLink"
import LoginButton from "./LoginButton"
import SurveyButton from "./SurveyButton"
import SopButton from "./SopButton"

const FooterMain = ({ content }) => {
  return (
    <div className="flex flex-col md:flex-row md:w-full">
      <div className="mb-12 text-center md:mb-0 md:mr-16 md:text-left">
        <Logo />
        <Contact content={content.footer} />
        <LoginButton content={content} />
        <SurveyButton />
        <SopButton />
      </div>
      <div className="flex flex-col md:flex-wrap md:flex-row md:flex-grow md:justify-start">
        {content.menu.map((item, index) => {
          return (
            <MenuCol key={index}>
              <Link to={item.menuLink}>
                <MenuTitle key={index}>{item.menuLabel}</MenuTitle>
              </Link>
              {item.childrens.map((itemSubmenu, index) => {
                return (
                  <MenuLink
                    key={index}
                    state={
                      itemSubmenu.state != undefined ? itemSubmenu.state : ""
                    }
                    menuLink={itemSubmenu.menuLink}
                  >
                    {itemSubmenu.menuLabel}
                  </MenuLink>
                )
              })}
            </MenuCol>
          )
        })}
      </div>
    </div>
  )
}

export default FooterMain
